<template>
    <div class="delete">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Delete Post</div>
        </div>

        <div class="main">
            <post_content :post="post" :blocked="false" :hidden="false" v-if="ready"/>
        </div>
        
        <div class="foot">
            <div class="note">This action cannot be reverted</div>

            <loader :height="'35px'" v-if="loading"/>
            <div class="btn" @click="remove()" v-else>Delete</div>
        </div>

    </div>
</template>

<script>
import back from '../../icons/back.vue';
import post_content from '../../components/post/content.vue';
import loader from '../../components/loading.vue';
export default {
    components: {
        back,
        post_content,
        loader
    },
    mounted() {
        this.post = JSON.parse(localStorage.getItem('delete'))
        setTimeout(() => {
            this.ready = true
        }, 200);
    },
    activated() {
        this.ready = false
        this.post = JSON.parse(localStorage.getItem('delete'))
        setTimeout(() => {
            this.ready = true
        }, 200);
    },
    
    data() {
        return {
            post: {},
            ready: false,
            loading: false
        }
    },
    methods: {
        back() {
            if (this.loading) { return }
            this.$router.go(-1)
        },
        remove() {
            this.loading = true

            let form = new FormData()
            form.append('post_id', this.post.id)

            this.$http.post('/post/delete', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.deleted()
                    
                    this.post.deleted = true
                    this.post.delete = JSON.stringify({
                        'date': r.date,
                        'by': 'Author'
                    })

                    this.$root.$data.new_delete = {
                        'id': this.post.id,
                        'by': 'Author',
                        'date': r.date
                    }
                    
                    localStorage.setItem('post', JSON.stringify(this.post))

                    this.$router.go(-1)
                }
                this.loading = false
            })
        }
    },
    notifications: {
        deleted: {
            type: 'success',
            title: 'Deleted',
            message: 'Post content has been removed'
        },
    }
}
</script>

<style scoped>
    .delete {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 40px) 50%;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }
    
    .main {
        height: calc(100vh - 144px);
        overflow: scroll;
    }
    textarea {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        outline: none;
        font-size: 16px;
    }


    .foot {
        border-top: 1px solid #ddd;
        padding: 15px;
    }
    .note {
        font-size: 12px;
        font-weight: 500;
        color: gray;
        margin-bottom: 10px;
    }
    .btn {
        font-weight: 500;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px;
        text-align: center;
    }

</style>